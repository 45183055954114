import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { navigationStyles, navigationSubMenuStyles, navStyles } from './Navigation.styles';
import './hamburguerNav.scss';

const Navigation: React.FC = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });

    const [hamburgerClass, setHamburgerClass] = useState(false);
    const history = useHistory();

    const handleClick = () => {
        setHamburgerClass(!hamburgerClass);
    };

    const setHamburgerFalse = () => {
        setHamburgerClass(false);
    };

    const options: ICommandBarItemProps[] = [
        {
            key: 'Home',
            text: 'Home',
            onClick: (e) => {
                history.push('/');
            },
            ...navigationStyles,
        },
        {
            ...navigationSubMenuStyles,
            key: 'certificates',
            text: 'Certificates',
            cacheKey: 'myCacheKey',
            subMenuProps: {
                items: [
                    {
                        key: 'Certificate of Sterility',
                        text: 'Certificate of Sterility',
                        onClick: (e) => {
                            history.push('/certs/Sterility');
                        },
                        ...navigationStyles,
                    },
                    {
                        key: 'Certificate of Analysis',
                        text: 'Certificate of Analysis',
                        onClick: (e) => {
                            history.push('/certs/Analysis');
                        },
                        ...navigationStyles,
                    },
                    {
                        key: 'Certificate of Origin',
                        text: 'Certificate of Origin',
                        onClick: (e) => {
                            history.push('/certs/Origin');
                        },
                        ...navigationStyles,
                    },
                ],
            },
        },
        {
            key: 'contact',
            text: 'Contact Us',
            href: 'mailto:mdservice.usa@fresenius-kabi.com',
            ...navigationStyles,
        },
        {
            key: 'terms',
            text: 'Terms of Use',
            href: 'https://www.fresenius-kabi.com/us/terms-of-use',
            target: '_blank',
            rel: 'noopener',
            ...navigationStyles,
        },
    ];

    const overflowProps: IButtonProps = {
        className: 'hamburger-box',
        ariaLabel: 'More commands',
        role: 'img',
        onAfterMenuDismiss: () => setHamburgerFalse(),
        onMenuClick: () => handleClick(),
        menuIconProps: {
            className: 'hamburger-inner',
            iconName: '',
        },
        styles: {
            rootExpanded: { backgroundColor: 'white' },
            rootExpandedHovered: { backgroundColor: 'white' },
        },
    };

    return (
        <div
            className={
                isTabletOrMobile && !hamburgerClass
                    ? 'hamburger hamburger--collapse-r'
                    : isTabletOrMobile && hamburgerClass
                    ? 'hamburger hamburger--collapse-r is-active'
                    : ''
            }
        >
            <CommandBar
                items={!isTabletOrMobile ? options : []}
                overflowItems={isTabletOrMobile ? options : []}
                styles={navStyles}
                overflowButtonProps={isTabletOrMobile ? overflowProps : {}}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
        </div>
    );
};

export default Navigation;
