import React from 'react';
import { NavLink } from 'react-router-dom';

import { getClassNames } from './Header.styles';

import Logo from '../../assets/fresenius-logo.png';
import Navigation from '../navigation/Navigation';

const Header: React.FC = () => {
    const { header, logo, navigationContainer, navigationOptions } = getClassNames();
    return (
        <header className={header}>
            <NavLink className={`ms-xl4`} to="/">
                <img src={Logo} width={211} height={90} className={logo} alt="logo" />
            </NavLink>
            <div className={`ms-xl8 ${navigationContainer}`}>
                <div className={navigationOptions}>
                    <Navigation />
                </div>
            </div>
        </header>
    );
};

export default Header;
